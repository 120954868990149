import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'dev2',
  ecomm: {
    ...base.ecomm
  },
  ngfeBaseUrl: 'https://dev2.sit.ordering.wingstop.com',
};
