<wri-feature-flags [with]="['enableApplePay']">
  <button
    tabindex="0"
    #applePay
    *ngIf="methodSupported"
    class="btn-primary apple-pay-button apple-pay-payment-method"
    data-testid="apple-pay-payment-method"
    aria-label="Apple Pay"
    (click)="onButtonClick($event)"
    id="apple-pay-button"
  ></button>
</wri-feature-flags>
